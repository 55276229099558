import React from 'react'
import * as styles from './styles.module.scss'

import Inview from 'components/Inview'

const IntroText = ({ content, hideBorder, addClass }) => {
  return (
    <Inview
      className={`${styles.introText}
      ${styles[addClass]}
        ${hideBorder ? styles.hideBorder : ''}
        fade-in slower 
    `}
    >
      <div className={styles.inner}>{content}</div>
    </Inview>
  )
}

export default IntroText
