import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './approach.module.scss'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import ImageWrap from 'components/ImageWrap'
import IntroText from 'components/IntroText'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

import { tr } from 'js/translations.js'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  const introText = renderRichText(page.introText)

  return (
    <Layout>
      <div>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} />
            <div className={styles.approachItems}>
              {page.pageApproachItems.map((item, i) => (
                <PageApproachItem key={i} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

const PageApproachItem = ({ item }) => {
  return (
    <Inview className={styles.approachItem}>
      <div className='fade-in'>
        <ImageWrap image={item.image} alt='' />
      </div>
      <div className={`${styles.text} fade-in-up`}>
        <h2>{item.title}</h2>
        <p>{item.description.description}</p>
      </div>
    </Inview>
  )
}

export const pageQuery = graphql`
  query {
    page: allContentfulApproachPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        pageApproachItems {
          title
          description {
            description
          }
          image {
            ...GatsbyImage
          }
        }
        seo {
          ...SeoFields
        }
      }
    }
  }
`
